import { Link } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import img1 from "../../../media/images/dashboard1.jpg";
import img2 from "../../../media/images/dashboard2.jpg";
import img3 from "../../../media/images/dashboard3.jpg";
import { AuthContext } from "../../../context/AuthContext";
import LoadingComponent from "../../../components/extra/LoadingComponent";
import AppMobileNavbar from "./AppMobileNavbar";
import AppPackageExpireComponent from "../main/AppPackageExpireComponent";
import SalesReportGraph from "./SalesReportGraph";

const AppDashboardComponent = () => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  // useState ============================================================================================
  const [search, setsearch] = useState("");

  const [businessData, setBusinessData] = useState(null);

  //  ============================================================================================
  const { fetchBusiness } = useContext(AuthContext);
  const fetchBusinessData = (e) =>
    fetchBusiness(userId, businessId, setBusinessData);

  useEffect(() => {
    fetchBusinessData();
  }, []);

  if (!businessData)
    return (
      <div>
        <LoadingComponent />
      </div>
    );

    const salesInvoiceData = businessData.salesInvoiceData.map((e) => {
      return { ...e, transactionType: "sales Invoice" };
    });
    const quotationData = businessData.quotationData.map((e) => {
      return { ...e, transactionType: "quotation" };
    });
    const proformaData = businessData.proformaData.map((e) => {
      return { ...e, transactionType: "proforma" };
    });
    const deliveryChallanData = businessData.deliveryChallanData.map((e) => {
      return { ...e, transactionType: "delivery Challan" };
    });
    const salesReturnData = businessData.salesReturnData.map((e) => {
      return { ...e, transactionType: "sales Return" };
    });
    const creditNoteData = businessData.creditNoteData.map((e) => {
      return { ...e, transactionType: "credit Note" };
    });
    const purchaseInvoiceData = businessData.purchaseInvoiceData.map((e) => {
      return { ...e, transactionType: "purchase Invoice" };
    });
    const purchaseReturnData = businessData.purchaseReturnData.map((e) => {
      return { ...e, transactionType: "purchase Return" };
    });
    const debitNoteData = businessData.debitNoteData.map((e) => {
      return { ...e, transactionType: "debit Note" };
    });
    const purchaseOrderData = businessData.purchaseOrderData.map((e) => {
      return { ...e, transactionType: "purchase Order" };
     });

     
   const staffTransactionData = businessData.staffTransactionData.map((e) => {
    return { ...e, 
      invoiceDate: e.transactionDate,
      transactionType: `Staff ${e.transactionType}`,
      invoiceNumber: e.expenseNumber,
      staffName: e.staffData.staffName,
      receivedAmount: e.transactionAmount,

     };
   });
     
     
   const expenseData = businessData.expenseData.map((e) => {
    return { ...e, 
      invoiceDate: e.expenseDate,
      transactionType: e.expenseCategory,
      invoiceNumber: e.expenseNumber,
      receivedAmount: e.expenseTotalAmount,
      transactionDate: e.expenseDate,
     };
   });
     
     
     
     const adjustBalanceData = businessData.adjustBalanceData;
     
     const transferBalanceData = businessData.transferBalanceData;
 
     // console.log("adjustBalanceData is : ", adjustBalanceData)
 
    const combinedData = [
      ...expenseData,
      ...staffTransactionData,
      ...transferBalanceData,
      ...adjustBalanceData,
      ...salesInvoiceData,
      ...quotationData,
      ...proformaData,
      ...deliveryChallanData,
      ...salesReturnData,
      ...creditNoteData,
      ...purchaseInvoiceData,
      ...purchaseReturnData,
      ...debitNoteData,
      ...purchaseOrderData,
    ];
  
    combinedData.sort(
      (a, b) => new Date(b.invoiceDate) - new Date(a.invoiceDate)
    );
  

  // -----------------------------------------------------------------------------

  

  // total --------------------------------

  const totalBalance = combinedData.reduce(
    (sum, e) =>
      e.transactionType === "sales Invoice" ||
      e.transactionType === "purchase Return" ||
      e.transactionType === "debit Note" ||
      e.transactionType === "Add Money"
        ? sum + e.receivedAmount
        : e.transactionType === "purchase Invoice" ||
          e.transactionType === "sales Return" ||
          e.transactionType === "credit Note" ||
          e.transactionType === "Reduce Money"
        ? sum - e.receivedAmount
        : sum,

    0
  );

  
  
  // -----------------------------------------------------------------------------
  // -----------------------------------------------------------------------------

  const partyTotalAmount = (id, openingBalance, openingBalanceType) => {
    const salesData =
      businessData &&
      businessData.salesInvoiceData.filter(
        (e) => e.invoicePartyData._id === id
      );

    const salesReturnData =
      businessData &&
      businessData.salesReturnData.filter((e) => e.invoicePartyData._id === id);

    const creditNoteData =
      businessData &&
      businessData.creditNoteData.filter((e) => e.invoicePartyData._id === id);

    const purchaseData =
      businessData &&
      businessData.purchaseInvoiceData.filter(
        (e) => e.invoicePartyData._id === id
      );

    const purchaseReturnData =
      businessData &&
      businessData.purchaseReturnData.filter(
        (e) => e.invoicePartyData._id === id
      );

    const debitNoteData =
      businessData &&
      businessData.debitNoteData.filter((e) => e.invoicePartyData._id === id);

    const salesBalance =
      salesData &&
      salesData.reduce(
        (e, data) => e + (data.invoiceTotalAmount - data.receivedAmount),
        0
      );
    const salesReturnBalance =
      salesReturnData &&
      salesReturnData.reduce(
        (e, data) => e + (data.invoiceTotalAmount - data.receivedAmount),
        0
      );
    const creditNoteBalance =
      creditNoteData &&
      creditNoteData.reduce(
        (e, data) => e + (data.invoiceTotalAmount - data.receivedAmount),
        0
      );

    const purchaseBalance =
      purchaseData &&
      purchaseData.reduce(
        (e, data) => e + (data.invoiceTotalAmount - data.receivedAmount),
        0
      );
    const purchaseReturnBalance =
      purchaseReturnData &&
      purchaseReturnData.reduce(
        (e, data) => e + (data.invoiceTotalAmount - data.receivedAmount),
        0
      );
    const debitNoteBalance =
      debitNoteData &&
      debitNoteData.reduce(
        (e, data) => e + (data.invoiceTotalAmount - data.receivedAmount),
        0
      );
    // return salesBalance;

    const totalBalance =
      openingBalanceType === "collect"
        ? salesBalance &&
          salesBalance -
            salesReturnBalance -
            creditNoteBalance -
            purchaseBalance +
            purchaseReturnBalance +
            debitNoteBalance +
            openingBalance
        : openingBalanceType === "pay"
        ? salesBalance &&
          salesBalance -
            salesReturnBalance -
            creditNoteBalance -
            purchaseBalance +
            purchaseReturnBalance +
            debitNoteBalance -
            openingBalance
        : "update balance";

    return totalBalance;
  };

  const toCollect = businessData.partyData
    .filter(
      (e) =>
        partyTotalAmount(
          e._id,
          e.partyOpeningBalance,
          e.partyOpeningBalanceType
        ) > 0
    )
    .reduce(
      (sum, e) =>
        sum +
        partyTotalAmount(
          e._id,
          e.partyOpeningBalance,
          e.partyOpeningBalanceType
        ),
      0
    );

  const toPay = Math.abs(
    businessData.partyData
      .filter(
        (e) =>
          partyTotalAmount(
            e._id,
            e.partyOpeningBalance,
            e.partyOpeningBalanceType
          ) < 0
      )
      .reduce(
        (sum, e) =>
          sum +
          partyTotalAmount(
            e._id,
            e.partyOpeningBalance,
            e.partyOpeningBalanceType
          ),
        0
      )
  );


// ==================================================================

  
  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };

// ==================================================================

  return (
    <div>
      <div className="app-dashboard-component">
        <div className="app-dashboard-component-main">
          <div className="app-dashboard-component-top">
            
            <div className="app-dashboard-component-top-heading"><AppMobileNavbar businessData={businessData}/>Dashboard</div>
            <div className="app-dashboard-component-top-buttons">
              <Link
                to="add-sale-invoice"
                className="app-dashboard-component-top-create-inv"
              >
                Create Sales Invoice
              </Link>
              {/* <Link
                to="https://wa.me/+919958501500?text=Hello ! I'm Interested in business solution service. Please, arrange free demo for me."
                target="_blank"
                className="app-dashboard-component-top-book-demo"
              >
                Book a Demo
              </Link> */}
            </div>
          </div>

          <div className="app-dashboard-component-content">

            <AppPackageExpireComponent businessData={businessData}/>
            
            <div className="app-dashboard-component-section-1">
              <div className="app-dashboard-component-row-2">
                <Link to="staff">
                <img src={img2} alt="" />
                </Link>
                <Link
                  to="https://wa.me/+919958501500?text=Hello ! I'm Interested in business solution service. Please, arrange free demo for me."
                  target="_blank"
                  className="app-dashboard-component-top-book-demo"
                >
                  <img src={img1} alt="" />
                </Link>
              </div>
            </div>

            <div className="app-dashboard-component-section-2">
              <div className="app-dashboard-component-row-2">
                <Link
                  to="parties#collect"
                  className="app-dashboard-component-section-2-col"
                >
                  <div className="app-dashboard-component-section-2-col-heading">
                    <i className="fa-solid fa-arrow-down"></i>
                    To Collect
                  </div>
                  <div className="app-dashboard-component-section-2-col-amount">
                    ₹{" "}
                    {new Intl.NumberFormat("en-IN").format(toCollect)}
                  </div>
                </Link>

                <Link
                  to="parties#pay"
                  className="app-dashboard-component-section-2-col"
                >
                  <div className="app-dashboard-component-section-2-col-heading">
                    <i className="fa-solid fa-arrow-up"></i>
                    To Pay
                  </div>
                  <div className="app-dashboard-component-section-2-col-amount">
                    ₹{" "}
                    {new Intl.NumberFormat("en-IN").format(toPay)}
                  </div>
                </Link>
              </div>  
            </div>

            <div className="app-dashboard-component-section-3">
              <div className="app-dashboard-component-section-3-col-1">
                <div className="app-transaction-page-table">
                  <div className="app-transaction-page-table-head">
                    <div>Date</div>
                    <div>Type</div>
                    <div>TXN NO</div>
                    <div>Name</div>
                    <div>Amount</div>
                  </div>
                  <div className="app-transaction-page-table-body">
                    {combinedData.filter( e => ( 
                      e.transactionType === "purchase Invoice" ||
                      e.transactionType === "sales Return" ||
                      e.transactionType === "credit Note" ||
                      e.transactionType === "sales Invoice" ||
                              e.transactionType === "purchase Return" ||
                              e.transactionType === "debit Note" ||
                              e.transactionDate

                     ) && e.receivedAmount ).slice(0, 5).map((e) => (
                      <>
                        <div>{new Date(e.invoiceDate).toDateString()}</div>
                        <div>{e.transactionType&&e.transactionType}</div>
                        <div>{e.invoiceNumber&&e.invoiceNumber}</div>
                      <div> { e.invoicePartyData ? e.invoicePartyData.partyName :    e.staffName ? e.staffName : e.withGst === true ? e.expensePartyData ? e.expensePartyData.partyName     :"--"   :"-"}</div>

                        {/* <div>{e.invoicePartyData&&e.invoicePartyData.partyName}</div> */}
                      <div>₹ {e.receivedAmount&&formatNumber(e.receivedAmount)}</div>
                      </>
                    ))}
                  </div>
                  {combinedData.filter( e => ( 
                      e.transactionType === "purchase Invoice" ||
                      e.transactionType === "sales Return" ||
                      e.transactionType === "credit Note" ||
                      e.transactionType === "sales Invoice" ||
                              e.transactionType === "purchase Return" ||
                              e.transactionType === "debit Note" ||
                              e.transactionDate
                     ) && e.receivedAmount ).length > 0 ? (
                    <div className="app-transaction-page-table-link">
                      <Link to="all-transaction">See all transaction</Link>
                    </div>
                  ) : (
                    <div className="app-transaction-page-table-link">
                      <br />
                      No Transactions
                      <br />
                      <br />
                    </div>
                  )}
                </div>

                <SalesReportGraph salesInvoiceData={salesInvoiceData} businessData={businessData} />
                
                
              </div>

              
            <div className="app-dashboard-small-list">

               <div className="app-dashboard-small-list-heading">
                <div>Latest Transactions</div>
                <Link to="all-transaction">All Transactions</Link>
              </div>

              <div className="app-dashboard-small-list-content">

              {combinedData.filter( e => ( 
                      e.transactionType === "purchase Invoice" ||
                      e.transactionType === "sales Return" ||
                      e.transactionType === "credit Note" ||
                      e.transactionType === "sales Invoice" ||
                              e.transactionType === "purchase Return" ||
                              e.transactionType === "debit Note" ||
                              e.transactionDate

                     ) && e.receivedAmount ).slice(0, 5).map((e) => (
                     
                <div className="app-dashboard-small-list-col">
                  
                  <div className="app-dashboard-small-list-row">
                    <div className="app-dashboard-small-list-name">{ e.invoicePartyData ? e.invoicePartyData.partyName :    e.staffName ? e.staffName : e.withGst === true ? e.expensePartyData ? e.expensePartyData.partyName     :"--"   :"-"}</div>
                    <div className="app-dashboard-small-list-date">{new Date(e.invoiceDate).toDateString()}</div>
                  </div>

                  <div className="app-dashboard-small-list-row">
                    <div className="app-dashboard-small-list-invoice">{e.transactionType&&e.transactionType} #{e.invoiceNumber&&e.invoiceNumber}</div>
                    <div className="app-dashboard-small-list-amount">₹ {e.receivedAmount&&formatNumber(e.receivedAmount)}</div>
                  </div>

                </div>
              ))}


{combinedData.filter( e => ( 
                      e.transactionType === "purchase Invoice" ||
                      e.transactionType === "sales Return" ||
                      e.transactionType === "credit Note" ||
                      e.transactionType === "sales Invoice" ||
                              e.transactionType === "purchase Return" ||
                              e.transactionType === "debit Note" ||
                              e.transactionDate
                     ) && e.receivedAmount ).length > 0 ? (
                    <div className="app-transaction-page-table-link"></div>
                  ) : (
<div className="app-dashboard-small-list-empty">No Transactions</div>

                  )}
                
                
              </div>

            </div>


              

              <div className="app-dashboard-component-section-3-col-2">

                
                <Link to="cash-bank" className="app-dashboard-component-section-3-col-2-cash-bank">
                  <div className="app-dashboard-component-section-3-col-2-cash-bank-heading">
                    <svg
                      width="16"
                      height="19"
                      viewBox="0 2 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.2667 1.41068C11.7223 1.15542 12.2777 1.15542 12.7333 1.41069L21.7105 6.44123C23.0582 7.19644 22.5221 9.24978 20.9772 9.24978H3.02281C1.47793 9.24978 0.941839 7.19644 2.28954 6.44123L11.2667 1.41068Z"
                        fill="#488dff"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4 17.9998V8.99978H6V17.9998H4Z"
                        fill="#488dff"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9 17.9998V8.99978H11V17.9998H9Z"
                        fill="#488dff"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13 17.9998V8.99978H15V17.9998H13Z"
                        fill="#488dff"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18 17.9998V8.99978H20V17.9998H18Z"
                        fill="#488dff"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1 21.9998C1 21.4475 1.44772 20.9998 2 20.9998H22C22.5523 20.9998 23 21.4475 23 21.9998C23 22.5521 22.5523 22.9998 22 22.9998H2C1.44772 22.9998 1 22.5521 1 21.9998Z"
                        fill="#488dff"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2 18.9998C2 18.4475 2.44772 17.9998 3 17.9998H21C21.5523 17.9998 22 18.4475 22 18.9998C22 19.5521 21.5523 19.9998 21 19.9998H3C2.44772 19.9998 2 19.5521 2 18.9998Z"
                        fill="#488dff"
                      ></path>
                    </svg>{" "}
                    Total Cash + Bank Balance
                  </div>
                  <div className="app-dashboard-component-section-3-col-2-cash-bank-rs">
                    ₹{" "}
                    {new Intl.NumberFormat("en-IN").format(totalBalance)}
                  </div>
                </Link>

                <div className=""></div>

                <div className="app-dashboard-component-section-3-col-2-image">
                  <Link to="add-sale-invoice">
                  <img src={img3} alt="" />
                  </Link>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppDashboardComponent;
