import React, { useContext } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import Barcode from "react-barcode";

const ViewInvoiceThermalTheme = ({
  data,
  businessData,
  heading,
  invoiceType,
}) => {
  const { link } = useContext(AuthContext);

  

  // ------------------------------------------------------------------------------------------------------------------
  let invoiceNumber = data&&data.invoiceNumber;
  let barcodeNumber = String(invoiceNumber).padStart(15, "0");
  console.log(barcodeNumber); 
  // ------------------------------------------------------------------------------------------------------------------
  
  // ------------------------------------------------------------------------------------------------------------------
  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };
  // ------------------------------------------------------------------------------------------------------------------
  


  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  

  
  const calculateTaxedPrice = (price, taxRate, quantity, discount, cess) => {
    const discountedPrice = price - (price * discount) / 100;

    return (
      (discountedPrice +
        (discountedPrice * taxRate) / 100 +
        (cess * discountedPrice) / 100) *
      quantity
    );
  };

  
  
  

  return (
    <div>
      <div className="thermal-invoice">
        <div className="thermal-invoice-main">
          <div className="thermal-invoice-header">
            {businessData.companyLogo ? (
              <div className="thermal-invoice-header-logo">
                <img src={`${link}/${businessData.companyLogo}`} />
              </div>
            ) : (
              ""
            )}

            <div className="thermal-invoice-header-company-name">
              {businessData.businessName}
            </div>

            <div className="thermal-invoice-header-address">
              {businessData.companyBillingAddress ? (
                <>{businessData.companyBillingAddress},</>
              ) : (
                ""
              )}
              {businessData.companyCity ? (
                <> {businessData.companyCity},</>
              ) : (
                ""
              )}
              {businessData.companyState ? (
                <> {businessData.companyState},</>
              ) : (
                ""
              )}
              {businessData.companyPincode ? (
                <> {businessData.companyPincode}</>
              ) : (
                ""
              )}
            </div>

            <div className="thermal-invoice-header-info">
              {businessData.companyPhoneNumber ? (
                <div className="thermal-invoice-header-info-col">
                  <div>Mob :</div>
                  <span>{businessData.companyPhoneNumber}</span>
                </div>
              ) : (
                ""
              )}

              {businessData.companyEmail ? (
                <div className="thermal-invoice-header-info-col">
                  <div>Email :</div>
                  <span>{businessData.companyEmail}</span>
                </div>
              ) : (
                ""
              )}

              {businessData.companyGSTIN ? (
                <div className="thermal-invoice-header-info-col">
                  <div>GSTIN :</div>
                  <span>{businessData.companyGSTIN}</span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="thermal-invoice-details">
            <div className="thermal-invoice-heading">Tax Invoice</div>
            <table>
              <tr>
                <td>Invoice No.:</td>
                <td>
                  {data.invoiceNumber ? data.invoiceNumber : "__________"}
                </td>
              </tr>

              <tr>
                        <td>Billed To:</td>
                        <td>{data.invoicePartyData && data.invoicePartyData.partyName}</td>
                      </tr>

              <tr>
                <td>Bill Date</td>
                <td>
                  {data.invoiceDate ? (
                    <>
                      {new Date(data.invoiceDate)
                        .getDate()
                        .toString()
                        .padStart(2, 0)}
                      /
                      {(new Date(data.invoiceDate).getMonth() + 1)
                        .toString()
                        .padStart(2, 0)}
                      /{new Date(data.invoiceDate).getFullYear()}
                    </>
                  ) : (
                    "__________"
                  )}
                </td>
              </tr>

              {data.invoiceEnableDueDate ? (
                <tr>
                  <td>Due Date</td>
                  <td>
                    {new Date(data.invoiceDueDate)
                      .getDate()
                      .toString()
                      .padStart(2, 0)}
                    /
                    {(new Date(data.invoiceDueDate).getMonth() + 1)
                      .toString()
                      .padStart(2, 0)}
                    /{new Date(data.invoiceDueDate).getFullYear()}
                  </td>
                </tr>
              ) : (
                ""
              )}
            </table>
          </div>

          <div className="thermal-invoice-items">
            <div className="thermal-invoice-items-heading">
              <div>Number of Items</div>
              <div>{data&&data.invoiceItemsData.length}</div>
            </div>

            <table>
              <tr>
                <td>Item</td>
                <td>Price</td>
                <td>Qty.</td>

                {
                  data.invoiceItemsData.filter((e) => e.discount).length > 0 ?
                  <td>Disc.</td>
                  :""
                }

                {
                  data.invoiceItemsData.filter((e) => e.itemGst).length > 0 ?
                  <td>Tax</td>
                  :""
                }
                <td>Amount</td>
              </tr>
{
  data.invoiceItemsData.map( e => (
              <tr>
                <td>{e.itemName}</td>
                <td>{
                        invoiceType === "Purchase"?
                      formatNumber(Number(e.itemPurchasePrice))*1
                      :
                      formatNumber(Number(e.itemPrice))*1
                      }</td>
                <td>{formatNumber(Number(e.quantity))*1}</td>


                {
                  data.invoiceItemsData.filter((e) => e.discount).length > 0 ?
                  <td>{e.discount ? formatNumber(e.discount)*1 : 0}</td>
                  :""
                }

                {
                  data.invoiceItemsData.filter((e) => e.itemGst).length > 0 ?
                  <td>{e.itemGst ? e.itemGst : 0} {e.cess ? `,${e.cess}` : ""}</td>
                  :""
                }
                
                <td>
                {
                        invoiceType === "Purchase"?
                      formatNumber(
                        calculateTaxedPrice(
                          e.itemPurchasePrice,
                          e.itemGst,
                          e.quantity,
                          e.discount,
                          e.cess
                        )
                      ) * 1
                      :
                      formatNumber(
                        calculateTaxedPrice(
                          e.itemPrice,
                          e.itemGst,
                          e.quantity,
                          e.discount,
                          e.cess
                        )
                      ) * 1
                      
                      }
                </td>
              </tr>
  ) )
}



            </table>

            <div className="thermal-invoice-items-end">
              <div className="thermal-invoice-items-end-col">
                <div>Net Amount</div>
                <div>300</div>
              </div>

              <div className="thermal-invoice-items-end-col">
                <div>SGST @ 2.5 %</div>
                <div>10</div>
              </div>

              <div className="thermal-invoice-items-end-col">
                <div>CGST @ 2.5 %</div>
                <div>10</div>
              </div>

              <div className="thermal-invoice-items-end-col">
                <div>Bill Amount</div>
                <div>232324</div>
              </div>
            </div>
          </div>

          {/* ------------------------------------------------------------ */}

          <div className="thermal-invoice-items-barcode">
          
            <Barcode
              fontSize={11}
              value={barcodeNumber && barcodeNumber}
              width={1.5}
              height={50}
              background="#fff"
              lineColor="#000"
            />
          </div>

          {/* ------------------------------------------------------------ */}
        </div>
      </div>
    </div>
  );
};

export default ViewInvoiceThermalTheme;
